<template>
  <v-card flat width="100%">
    <!-- PARALLAX AQUI -->
    <br />

    <v-container fluid style="padding: 0">
      <v-card
        flat
        tile
        class="white--text align-end imageweb"
        style="height: 400px; position: relative"
        :img="bannerimg"
      >
              <div class="bannerColor">

        <v-card-title
          class="mobile-title"
          style="z-index: 1000; font-family: 'Montserrat', sans-serif;top: 50%;position:absolute;left:25%;right:10%;"
          >Preguntas Frecuentes</v-card-title
        >
              </div>
      </v-card>
    </v-container>

    <v-container fluid>
      <v-container>
        <v-layout
          column
          align-center
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-expansion-panels accordion>
            <v-expansion-panel
              style="
                margin-top: 10px;
                margin-bottom: 10px;
                box-shadow: 0px 3px 6px #00000029;
              "
              v-for="(item, i) in faqs"
              :key="i"
            >
              <v-expansion-panel-header>
                <p style="font-weight: bold; margin: 10px; font-size: 15px">
                  {{ item.title }}
                </p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p style="font-size: 14px; max-width: 90%">
                  {{ item.text }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-container>
    </v-container>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    faqs: [
      {
        title: "¿Como se clasifican los residuos?",
        text:
          "En México de acuerdo a nuestra legislación ambiental se clasifican en Residuos de Manejo Especial y Residuos Peligrosos",
      },
      {
        title: "¿Qué son los Residuos de Manejo Especial?",
        text:
          "Son aquellos desechos que no contienen características de peligrosidad y que pueden ser susceptibles a valoración para reciclaje.",
      },
      {
        title: "¿Qué son los Residuos Peligrosos?",
        text:
          "Son aquellos desechos que no contienen características de peligrosidad y que pueden ser susceptibles a valoración para reciclaje.",
      },
      {
        title: "¿Que significa CRETIB?",
        text:
          "Son características de peligrosidad por sus siglas Corrosivo, Reactivo, Explosivo, Tóxico, Inflamable y Bioinfeccioso",
      },
      {
        title: "¿Qué es un Manifiesto?",
        text:
          "Es un documento en el cual se registran las actividades de manejo de residuos ya sea de Manejo Especial o de Residuos Peligrosos",
      },
      {
        title: "¿Qué es un Generador de Residuos?",
        text:
          "Se refiere a cualquier persona física o moral que en su actividad genere desechos ya sean de Manejo Especial o Peligrosos",
      },
      {
        title: "¿Qué es un prestador de servicios de residuos?",
        text:
          "Se define a una persona física o moral el cual está Autorizada por las dependencias que regulan las actividades de las diferentes etapas del manejo de Residuos de Manejo Especial o Peligrosos",
      },
      {
        title: "¿Qué es una Autorización para manejo de Residuos?",
        text:
          "Es un documento Legal expedido por una Dependencia de Gobierno la cual regula las actividades del manejo de Residuos",
      },
    ],
    headerEsp: "",
    headerEng: "",
    formDesc: "",
    formDescEng: "",
    dialog: false,
    dialog2: false,
    myerror: "",
    orderList: [
      "Solicitud de empleo",
      "Solicitud de proveedor",
      "Información de nuestros servicios",
      "Otro",
    ],
    OrderListEng: [
      "Job application",
      "Vendor request",
      "Information about our services",
      "Other",
    ],
    name: "",
    email: "",
    phone: "",
    message: "",
    motive: "",
    nameTitle: "Nombre*",
    mailTitle: "Correo Electrónico*",
    phoneTitle: "Teléfono*",
    companyTitle: "Empresa*",
    messageTitle: "Mensaje*",
    bannerimg: "/static/DSC_003222.png",
  }),
  methods: {},
  mounted() {},
  computed: {
    language() {
      // iniciatlize global language for this section ( 0 = spanish, 1 = english)
      return this.$store.state.language;
    },
  },
};
</script>
<style scoped>

.bannerColor {
  background-color: #00000080;
  height: 100%;
  width: 100%;
}

.label_contact_input {
  font-size: 12px;
  color: #cfcfcf;
}
.contact_form_container {
  margin-top: 20px;
}
.submit_form {
  margin-top: 50px;
}
.border_coverage {
  margin-left: -2px;
}
.input_holder {
  width: 100%;
  margin-bottom: 20px;
  height: 40px;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #26e07c;
  /*   border-top-style: solid;
  border-top-width: .2px;
  border-top-color: #CFCFCF;
  border-right-style: solid;
  border-right-width: .2px;
  border-right-color: #CFCFCF;
  border-right-style: solid;
  border-bottom-width: .2px;
  border-bottom-color: #CFCFCF; 
  border-bottom: 20%;*/
}
.input_holder_area {
  padding-left: -10px;
  width: 100%;
  margin-bottom: 20px;
  height: 117px;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #26e07c;
  /*   border-top-style: solid;
  border-top-width: .2px;
  border-top-color: #CFCFCF;
  border-right-style: solid;
  border-right-width: .2px;
  border-right-color: #CFCFCF;
  border-right-style: solid;
  border-bottom-width: .2px;
  border-bottom-color: #CFCFCF;
  border-bottom: 20%; */
}
.contact_form_wrapper {
  max-width: 70%;
  width: 10000px;
}
.contact_form_wrapper_mobile {
  max-width: 90%;
}
.text_title {
  font-weight: 700;
  font-family: "Open sans", sans-serif;
  font-size: 10px;
  color: #091f2c;
  text-align: left;
}
.contact_text_form {
  font-size: 20px;
  color: white;
  margin: auto;
  font-weight: 0;
}

.mobile-title {
  margin: 0 auto;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
  font-size: 8vh;
}
.contact-mobile {
  max-width: 80%;
}

@media (max-width: 900px) and (min-width: 0px) {
  .mobile-title {
    margin: 0 auto;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 600;
    font-size: 5vh;
  }
}
</style>